.lobbyTopContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
}

.lobbyTopItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 6rem;
	padding: 0 20px;
	color: black;
}

.lobbyTopItem .iconMember {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: #dcedfd;
	border-radius: 50%;
}

.lobbyTopItem .iconPlayer {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: rgb(184, 217, 249);
	border-radius: 50%;
}

.lobbyTopDivider {
	width: 1px;
	height: 30px;
	border: 1px solid rgba(138, 197, 255, 0.5);
}

.hr-line {
	border: 1px solid rgba(138, 197, 255, 0.5);
	opacity: 1 !important;
	margin: 0.5rem 0 !important;
}

.hr-line-mobile {
	border: 1px solid rgba(138, 197, 255, 0.5);
	opacity: 1 !important;
	margin: 0.5rem 30px !important;
}

.offcanvas-body {
	background-color: rgb(32, 32, 32) !important;
	border-radius: 8px !important;
	border: 1px solid grey !important;
	height: 100%;
}

.sidebar {
	background: black !important;
	border-right: 1.5px solid rgb(82, 82, 82);
}

.nav-item {
	position: relative;
}

/* .nav-item.active .nav-link,
.nav-item.active .logout-nav,
.text-black {
	color: black;
} */

.provider-link.active {
	background-color: #e0e0e0;
}

.subnav {
	background-color: white !important;
	border-top: 1px solid rgb(211, 211, 211);
	margin-bottom: -1px;
}

.nft .slick-dots {
	bottom: 5px !important;
}

.slick-slide img {
	aspect-ratio: 0 !important;
	height: 240px !important;
	width: 100% !important;
	object-fit: contain !important;
}

@media (min-width: 768px) {
	.slick-slide img {
		aspect-ratio: 0 !important;
		height: 350px !important;
		width: 100% !important;
		object-fit: contain !important;
	}
}

.slick-slide-text {
	font-size: 16px;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.slick-slide img {
		aspect-ratio: 0 !important;
		height: 100% !important;
		width: 100% !important;
	}

	.slick-slide-text {
		font-size: 12px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}